import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter, Switch, Route} from "react-router-dom"

import "./index.css"
import "typeface-poppins"

import Home from "./pages/Home"
import ScrollToTop from "./components/ScrollToTop"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
