import {Helmet} from "react-helmet"

import Hero from "../components/Hero"
import About from "../components/About"
import Timeline from "../components/Timeline/Timeline.js"
import Clients from "../components/Clients"
import Footer from "../components/Footer"

function Home() {
    return (
        <>
            <Helmet>
                <meta property="og:title" content="Krystian Nikiel"/>
                <meta property="og:description"
                      content="Developer"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="#"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="628"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content=""/>
                <meta name="twitter:creator" content=""/>
                <meta name="twitter:title" content=""/>
                <meta name="twitter:description"
                      content="Developer"/>
                <meta name="twitter:image" content=""/>
            </Helmet>
            <Hero/>
            <main className="pb-20 bg-white main">
                <div className="container">
                    <p></p>
                    <p></p>
                    <button></button>
                </div>
                <div className="container flex">
                    <About/>
                    <Timeline/>
                </div>
                <Clients/>
            </main>
            <Footer/>
        </>
    )
}

export default Home
